<template>
    <div id="expert-consultation">
        <left-menu class="left-menu" :menus="menus" :defaultIndex="defaultIndex" :mainMenuIndex='mainMenuIndex'></left-menu>
        <div class="main-content">
            <public-header class="public-header"></public-header>
            <router-view class="main"></router-view>
        </div>
    </div>
</template>
<script>
import LeftMenu from "@comp/left_menu.vue";
import PublicHeader from "@comp/public_header.vue";
export default {
    name: "expert_consultation",
    data() {
        return {
            systems: [],
            menus: [
                {
                    id: '8001',
                    name: "咨询专家",
                    routerName: "consulting_expert",
                    bgPicture: "menu/zjzx_consulting_expert"
                },
                {
                    id: '8002',
                    name: "专家分析",
                    routerName: "expert_analysis",
                    bgPicture: "menu/zjzx_expert_analysis"
                },
                {
                    id: '8003',
                    name: "专家信息",
                    routerName: "expert_information",
                    bgPicture: "menu/zjzx_expert_information"
                }

            ],
            defaultIndex: 0,
            mainMenuIndex: '800'
        };
    },
    components: {
        LeftMenu,
        PublicHeader,
    },
    created() {
        let routeName = this.$route.name;
        switch (routeName) {
            case "expert_analysis":
                this.defaultIndex = 1;
                break;
            default:
                if(routeName.includes('consulting_expert_grid')){
                    this.defaultIndex = 0;
                } else if(routeName.includes('expert_information_grid')) {
                    this.defaultIndex = 2;
                }
                break;
        }
    },
}
</script>
<style lang="scss" scoped>
#expert-consultation {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    .left-menu {
        flex: 0 0 100px;
    }
    .main-content {
        flex: auto;
        display: flex;
        flex-flow: column nowrap;
        .public-header {
            flex: none;
        }
        .main {
            width: 100%;
            height: calc(100% - 30px);
            padding: 0 20px;
            background-color: #eaece6;
        }
    }
}
</style>